#contactDiv {
    color: black;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.contactMeTitle {
    align-items: center;
    align-self: center;
    margin-bottom: 1%;
}

.contactHeader {
    background-color: white !important;
    border-bottom: none !important;
    font-size: 30px;
}

.emailCard {
    width: 49.5%;
    float: left;
    margin-right: .25%;
}

.callCard {
    width: 49.5%;
    float: right;
    margin-left: .25%;
}