@import 'bootstrap/dist/css/bootstrap.min.css';

.title {
    text-align: center;
}

.intro {
    text-align: center;
}

.profPhoto {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.homeLists {
    margin-bottom: 10%;
}

.repairContact {
    text-align: center;
    margin-top: 1%;
}

.repairContactBtn {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
