@import url(https://fonts.googleapis.com/css2?family=Kanit&family=Yellowtail&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  background-color: black !important;
}

.mainDiv {
  margin-top: 10%;
  color: white;
}

h1 {
  font-family: 'yellowtail';
  font-size: 100px !important;
}

h2 {
  font-family: 'yellowtail';
  font-size: 60px !important;
}

h3, h4, h5, h6, p, ul, li, ol{
  font-family: 'kanit';
}

h3 {
  font-size: 50px !important;
}
.navBar {
    padding: 2%;
}
.title {
    text-align: center;
}

.intro {
    text-align: center;
}

.profPhoto {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.homeLists {
    margin-bottom: 10%;
}

.repairContact {
    text-align: center;
    margin-top: 1%;
}

.repairContactBtn {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#myWorkDiv {
    margin-left: 1%;
    margin-right: 1%;
}

.buggyPhoto {
   width: 100%;
}
#contactDiv {
    color: black;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.contactMeTitle {
    align-items: center;
    align-self: center;
    margin-bottom: 1%;
}

.contactHeader {
    background-color: white !important;
    border-bottom: none !important;
    font-size: 30px;
}

.emailCard {
    width: 49.5%;
    float: left;
    margin-right: .25%;
}

.callCard {
    width: 49.5%;
    float: right;
    margin-left: .25%;
}
